<template>
  <div>
    <PageHeader>
      <template slot="right">
        <b-button 
        v-if="$allowPermission('master:manage.agent')"
        to="/agents/create" variant="primary">
        <div
        v-if="agentType == 'OWNER'"
        >
          <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add_master') }}
        </div>
        <div
        v-else
        >
          <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add_agent') }}
        </div>
        </b-button>
      </template>
    </PageHeader>
    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
      </h6>
      <b-row align-v="center" class="mb-3">
        <b-col cols="12" md="3">
          <LimitSelect v-model="selectedLimit"/>
        </b-col>
        <b-col cols="12" md="9">
          <b-row>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="search"
                  :placeholder="`${$t('fields.search')}...(ชื่อผู้ใช้/ รหัสสมาชิก/ ชื่อ) `"
                  class="mx-1"
                  type="search"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col v-if="isOwner" cols="3">
              <MasterSelectInput 
                :value="selectedMaster"
                hide-label
                @update="onMasterIdChange"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :busy="isFetching"
        :fields="fields"
        :items="agentList"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link :to="`/agents/detail/${data.item.id}`">
            {{ `${data.value || ''} ${data.item.lastname || ''}` }}
          </b-link>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="$displayStatusColor(data.value)">
            {{
              $te(`utils.status.${data.value}`)
                ? $t(`utils.status.${data.value}`)
                : data.value
            }}
          </b-badge>
        </template>
        <template #cell(isEnableLineAuth)="data">
          <div>
            <b-badge v-if="data.value" variant="success">เปิด</b-badge>
            <b-badge v-else variant="danger">ปิด</b-badge>
          </div>
        </template>
        <template #cell(isBanned)="data">
          <div>
            <b-badge v-if="data.value" variant="danger">ระงับ</b-badge>
            <b-badge v-else variant="success">ปกติ</b-badge>
          </div>
        </template>
        <template #cell(lineId)="data">
          {{ data.value || '-' }}
        </template>
        <template #cell(id)="data">
          <b-dropdown dropleft size="sm" variant="info">
            <template #button-content>
              <i class="uil uil-cog"></i>
            </template>
            <b-dropdown-item 
            v-if="$allowPermission('master:manage.agent')"
            :to="`/agents/detail/${data.item.id}`">
              <i class="uil uil-edit-alt mr-2"></i>
              {{ $t('buttons.edit') }}
            </b-dropdown-item>
            <b-dropdown-item
              :to="`/bank-accounts/create?agentId=${data.item.id}`"
            >
              <i class="uil uil-university mr-2"></i>
              {{ $t('buttons.add_bank_account') }}
            </b-dropdown-item>
            <b-dropdown-item @click="toggleDirectDepositModal(data.value)">
              <feather
                class="icon-xs text-success"
                type="plus-circle"
              ></feather>
              <span class="pl-2 text-success">
                {{ $t('credits.direct_deposit') }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="toggleDirectWithdrawModal(data.value)">
              <feather
                class="icon-xs text-warning"
                type="minus-circle"
              ></feather>
              <span class="pl-2 text-warning">
                {{ $t('credits.direct_withdraw') }}
              </span>
              
            </b-dropdown-item>
            <b-dropdown-item :href="`/credits/master-agent`" disabled>
              <i class="uil uil-exchange mr-2"></i>
              {{ $t('credits.credit_transaction') }}
            </b-dropdown-item>
            <b-dropdown-item @click="onLogin(data.value)">
              <i class="uil uil-sign-out-alt mr-2"></i>
              {{ $t('buttons.access_login') }}
            </b-dropdown-item>
            <b-dropdown-item @click="onBan(data.value)">
              <span class="text-danger">
                <i class="uil uil-ban mr-2"></i>
                {{ data.item.isBanned ? 'ปลดระงับ' : 'ระงับ'}}
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="onDelete(data.value)">
              <span class="text-danger">
                <i class="uil uil-trash-alt mr-2"></i>
                {{ $t('buttons.remove') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
        
      </b-table>
      <!-- pagination -->
      <PaginationInput :per-page="limit" :total="total" @update="(val) => currentPage = val"/>
    </b-card>
      <DirectDepositModal
        ref="directDepositTransfer"
        :agent-id="selectedAgentId"
        no-btn
      />
      <DirectWithdrawModal
        ref="directWithdrawTransfer"
        :agent-id="selectedAgentId"
        no-btn
      />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'รายการเอเย่นต์',
  },
  components: {
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
    DirectDepositModal: () =>
      import('@components/credits/direct-deposit-agent-modal'),
    DirectWithdrawModal: () =>
      import('@components/credits/direct-withdraw-agent-modal'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      search: '',
      onSearchTimeout: null,
      selectedMaster: '',
      selectedAgentId: '',
      selectedNote: '',
      fields: [
        '#',
        {
          key: 'name',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '180px',
          },
        },
        {
          key: 'prefix',
          label: 'prefix',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'registrationUrl',
          label: 'ลิ้งค์สมัคร',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'lineId',
          label: 'Line @ID',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'isEnableLineAuth',
          label: this.$t('user.has_line_signin'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'isBanned',
          label: 'สถานะ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'id',
          label: '-',
          class: 'text-right',
        },
      ],
      selectedAgent: '',
      agentType: ''
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.agent.isFetchingAgents,
    }),
    ...mapGetters(['isOwner', 'agents']),
    agentList() {
      return this.agents?.items || []
    },
    pagination() {
      return this.agents.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
       if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
    if (this.isOwner) {
      this.addFieldForOwner('agentType')
      this.agentType = 'OWNER'
    } else {
      this.agentType = 'MASTER'
    }
  },
  methods: {
    ...mapActions(['fetchAgents', 'loginAsAgent', 'deleteAgent', 'banAgent']),
    fetchData() {
      this.fetchAgents({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    addFieldForOwner(key) {
      const found = this.fields.find((field) => field.key === key)
      if (!found) {
        this.fields.splice(1, 0, {
          key,
          label: this.$t('roles.title'),
        })
      }
    },
    toggleDirectDepositModal(id) {
      this.$refs.directDepositTransfer.toggleModal()
      this.selectedAgentId = id
    },
    toggleDirectWithdrawModal(id) {
      this.$refs.directWithdrawTransfer.toggleModal()
      this.selectedAgentId = id
    },
    onLogin(id){
      this.loginAsAgent(id);
    },
    onDelete(agentId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteAgent(agentId)
          }
        })
    },
    onBan(agentId) {
      this.$bvModal
          .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
            okTitle: `${this.$t('buttons.confirm')}`,
            cancelTitle: `${this.$t('buttons.cancel')}`,
          })
          .then((confirm) => {
            if (confirm) {
              this.banAgent(agentId)
            }
          })
    }
  },
}
</script>
